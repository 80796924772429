import greEnglish from 'translations/gre/english.json';
import greSwedish from 'translations/gre/swedish.json';
import greLogo from 'assets/media/gre/logo.svg';
import greSundprojektLogo from 'assets/media/gre/sundprojektLogo.svg';
import greFavicon from 'assets/media/gre/favicon.ico';
import greThemes from 'global/tenant-themes/gre-themes.json';
import greWelcome from 'assets/media/gre/welcome.jpg';
import greHome from 'assets/media/grt/home.png';
import { GRELivingInfo } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/GRELivingInfo';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { GREEN_BG } from 'services/user/settings/utils';

export const greTenant = {
  initialBackground: GREEN_BG,
  assets: {
    logoWhite: greLogo,
    logoDark: greLogo,
    subBranchLogo: greSundprojektLogo,
    favicon: greFavicon,
    livingRoom: greHome,
    welcome: greWelcome,
  },
  intl: {
    english: greEnglish,
    swedish: greSwedish,
  },
  myHome: {
    components: {
      livingInfo: GRELivingInfo,
    },
    generalInfo: {
      link: '',
      title: 'Green Hub',
    },
  },
  themes: greThemes,
  sizes: {
    brikk: '300px',
    'brikks-spacing': '45px',
  },
  multiGrids: [MultiGrid.Home, MultiGrid.Neighbourhood, MultiGrid.Society, MultiGrid.Sustainability, MultiGrid.Store],
  brikks: ['A01', 'A02', 'A06', 'A07', 'A08', 'A12', 'H10', 'A17', 'A20'],
};
