import React, { useState } from 'react';
import classNames from 'classnames';
import { Card } from 'components/cards/Card';
import { useRouteMatch } from 'react-router-dom';
import { HOME_PAGE, ORDER_PAGE, AGREEMENT, CONTACT } from 'global/routes';
import { ShouldRender } from 'components/ShouldRender';
import { HamburgerMenu } from 'assets/icons/HamburgerMenu';
import { SideMenu } from 'components/headers/SideMenu';
import { MultiGrid } from './components/MultiGrid';
import { Menu } from './components/Menu';
import { Logo } from './components/Logo';
import styles from './styles.module.scss';
import { MobileMenu } from './components/MobileMenu';

export const AppHeader = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isExact: isHomePage } = useRouteMatch({ path: [AGREEMENT, CONTACT, HOME_PAGE] });
  const isOrderPage = useRouteMatch({ path: ORDER_PAGE }) !== null;

  return (
    <header className={classNames(
      styles.header, {
        [styles.fixed]: !isHomePage && !isOrderPage,
      },
    )}
    >
      <Card
        transparent={!isHomePage && !isOrderPage}
        className={styles.card}
      >
        <div className={styles.menu}>
          <ShouldRender condition={isHomePage || isOrderPage}>
            <HamburgerMenu
              className={styles.hamburgerMenu}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </ShouldRender>
          <Logo />

          <ShouldRender condition={isHomePage || isOrderPage}>
            <Menu />
          </ShouldRender>
        </div>

        <ShouldRender condition={isHomePage}>
          <MultiGrid />
        </ShouldRender>
      </Card>

      <SideMenu
        shouldShow={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MobileMenu onClick={() => setIsMenuOpen(false)} />
      </SideMenu>
    </header>
  );
};
