import { Tenants } from 'global/tenant-config/tenants';
import { brikksTenant, lkfTenant, ribTenant, vxbTenant, grtTenant, greTenant } from 'global/tenants';

export const TenantConfig = {
  [Tenants.LKF.tenantId]: lkfTenant,
  [Tenants.VXB.tenantId]: vxbTenant,
  [Tenants.BRIKKS.tenantId]: brikksTenant,
  [Tenants.RIB.tenantId]: ribTenant,
  [Tenants.GRT.tenantId]: grtTenant,
  [Tenants.GRE.tenantId]: greTenant,
};
